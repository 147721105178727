import React from 'react';
import loadable from '@loadable/component';
import classNames from 'classnames';
import { Link } from 'gatsby';
import useScrollAndSetActiveSection from '../hooks/useScrollAndSetActiveSection';
import { SITE_METADATA, PAIDY_HOST } from '../constants';
import StrokeSvg from '../images/10th_anniversary/stroke.svg';
import StrokeWhiteSvg from '../images/10th_anniversary/stroke-white.svg';
import styles from '../styles/pages/10th_anniversary.module.scss';
import { Layout } from '../components';
import Header from '../components/10thAnniversary/Header';
import KeyVisual from '../components/10thAnniversary/KeyVisual';

const SectionWrapper = loadable(() =>
  import('../components/10thAnniversary/SectionWrapper')
);
const Section0 = loadable(() =>
  import('../components/10thAnniversary/Section0')
);
const Section1 = loadable(() =>
  import('../components/10thAnniversary/Section1')
);
const Section2 = loadable(() =>
  import('../components/10thAnniversary/Section2')
);
const Section3 = loadable(() =>
  import('../components/10thAnniversary/Section3')
);

const SEOProps = {
  ogImage: `${PAIDY_HOST}/ogp_10th_anniversary.png`,
  twitterImage: `${PAIDY_HOST}/ogp_10th_anniversary.png`,
  title: SITE_METADATA['10th_anniversary'].title,
  description: SITE_METADATA['10th_anniversary'].description,
};

const NAVS = ['ドラマ', 'お買い物データ', 'みんなの夢'];

export default function Campaign202303() {
  const activeSection = useScrollAndSetActiveSection(NAVS);

  return (
    <Layout SEOProps={SEOProps} hasNoHeader hasNoFooter>
      <div className={styles.container}>
        <Header />
        <KeyVisual id="トップ" />
        <nav className={styles.nav}>
          <div className={styles.navWrapper}>
            {NAVS.map((nav) => (
              <div
                key={nav}
                className={classNames(styles.navItem, {
                  [styles.navItemActive]: nav === activeSection,
                })}
              >
                <Link to={`#${nav}`}>{nav}</Link>
              </div>
            ))}
          </div>
        </nav>
        <SectionWrapper
          id="ドラマ"
          sectionNumber="01"
          sectionTitle={
            <>
              <span>ショートドラマ</span> <br />
              <span>「夢って、なんなの」</span>
            </>
          }
          sectionDescription={
            <>
              <span>主演になえなのさん、声の出演に福山潤さんを迎えた､</span>{' '}
              <br />
              <span>10周年を記念した特別なショートドラマ｡</span> <br />
              <span>やってみたいことや、欲しいものも、夢のひとつ｡</span> <br />
              <span>あなたの今の夢はなんですか？</span>
            </>
          }
          strokeSvg={StrokeSvg}
          className={styles.section0}
        >
          <Section0 />
        </SectionWrapper>
        <SectionWrapper
          id="お買い物データ"
          sectionNumber="02"
          sectionTitle={
            <>
              <span>お買い物データで振り返る</span> <br />
              <span>ペイディの10年</span>
            </>
          }
          strokeSvg={StrokeSvg}
          className={styles.section1}
        >
          <Section1 />
        </SectionWrapper>
        <SectionWrapper
          id="みんなの夢"
          sectionNumber="03"
          sectionTitle="お買い物で叶えたみんなの夢"
          sectionDescription={
            <>
              <span>ペイディで夢を叶えた</span> <br />
              <span>皆さんの声をご紹介します。</span>
            </>
          }
          strokeSvg={StrokeWhiteSvg}
          className={styles.section2}
          classNameHeader={styles.sectionHeader2}
        >
          <Section2 />
        </SectionWrapper>
        <div className={styles.section3Wrapper}>
          <div className={styles.section3}>
            <div className={styles.section3Content}>
              <SectionWrapper>
                <Section3 />
              </SectionWrapper>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
